import React from 'react';
import { Box, Container, Grid, Typography, styled, useTheme, useMediaQuery } from '@mui/material';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from '@/components/animate';
import useLocales from '@/locales/useLocales';
import BigHomeButton from '@/components/buttons/homeMobile';
import { PATH_AUTH } from '@/routes/paths';
import { isMobile } from 'react-device-detect';

// Define the use cases with AI-generated image URLs
const useCases = [
  {
    title: 'UseCaseSection.useCases.business.title',
    description: 'UseCaseSection.useCases.business.description',
    imageUrl: '/assets/images/useCase/766c518b-4f1f-4c8e-8184-64026da9c32a.jpg',
  },
  {
    title: 'UseCaseSection.useCases.instagram.title',
    description: 'UseCaseSection.useCases.instagram.description',
    imageUrl: '/assets/images/useCase/aceaaf78-2905-4d75-af69-8fbd0de08d92.jpg',
  },
  {
    title: 'UseCaseSection.useCases.tinder.title',
    description: 'UseCaseSection.useCases.tinder.description',
    imageUrl: '/assets/images/useCase/Untitled-1.jpg',
  },
  {
    title: 'UseCaseSection.useCases.professionalNetworking.title',
    description: 'UseCaseSection.useCases.professionalNetworking.description',
    imageUrl: '/assets/images/useCase/561671e6-2587-4475-a32f-52ce5dee9b01.jpg',
  },
  {
    title: 'UseCaseSection.useCases.artistPortfolio.title',
    description: 'UseCaseSection.useCases.artistPortfolio.description',
    imageUrl: '/assets/images/useCase/984ca7bc-7e4e-4714-a6ff-9b742ce71f62.jpg',
  },
  {
    title: 'UseCaseSection.useCases.eCommerceProductModels.title',
    description: 'UseCaseSection.useCases.eCommerceProductModels.description',
    imageUrl: '/assets/images/useCase/80cbb577-801e-438a-a777-321494545ae2.jpg',
  },
  // Add more use cases as needed
];

// Styled components for the section
const StyledSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.background.paper,
}));

const UseCaseCard = styled(m.div)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
  },
}));

const UseCaseImage = styled('img')({
  width: '100%',
  aspectRatio: '3/4',
  objectFit: 'cover',
  display: 'block',
});

const UseCaseContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const UseCaseSection: React.FC = () => {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <StyledSection>
      <Container>
        <Box textAlign="center" mb={6}>
          <m.div variants={varFade().inUp}>
            <Typography variant="h4" component="h2" gutterBottom>
              {translate('UseCaseSection.title')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {translate('UseCaseSection.description')}
            </Typography>
          </m.div>
        </Box>
        <Grid container spacing={4}>
          {useCases.map((useCase, index) => (
            <Grid item xs={6} sm={6} md={4} key={index}>
              <UseCaseCard variants={varFade().inUp} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                <UseCaseImage src={useCase.imageUrl} alt={useCase.title} />
                <UseCaseContent>
                  <Typography variant="h6" gutterBottom>
                    {translate(useCase.title)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {translate(useCase.description)}
                  </Typography>
                </UseCaseContent>
              </UseCaseCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </StyledSection>
  );
};

export default UseCaseSection;
