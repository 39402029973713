import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Dialog, DialogContent, IconButton, useTheme, useMediaQuery, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSwipeable } from 'react-swipeable';
import ShareIcon from '@mui/icons-material/Share';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { isMobile, isTablet } from 'react-device-detect';
import { Capacitor } from '@capacitor/core';
import useLocales from '@/locales/useLocales';

/**
 * Props for VideoModal component
 */
interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  videoUrl: string;
  posterUrl?: string;
  jobId?: string;
}

/**
 * VideoModal component
 * Displays a video in a modal with playback controls.
 */
const VideoModal: React.FC<VideoModalProps> = ({ open, onClose, videoUrl, posterUrl, jobId }) => {
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen is mobile
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const { translate } = useLocales();
  const isMobileOrTablet = isMobile || isTablet;
  const [showControls, setShowControls] = useState(false);

  // Add check for share support
  const isShareSupported = useMemo(() => {
    return 'share' in navigator && 'canShare' in navigator;
  }, []);

  // Swipeable handlers for mobile devices
  const swipeHandlers = useSwipeable({
    onSwipedDown: onClose,
    delta: 50, // Minimum distance(px) before a swipe is detected
    trackTouch: true,
    trackMouse: false,
  });

  useEffect(() => {
    // Fetch video blob for sharing and downloading
    const fetchVideoBlob = async () => {
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      setVideoBlob(blob);
    };

    fetchVideoBlob();
  }, [videoUrl]);

  const handleShare = async () => {
    if (videoBlob) {
      try {
        const file = new File([videoBlob], 'video.mp4', { type: 'video/mp4' });

        // First check if we can share files
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            files: [file],
          });
        } else {
          // Fallback to sharing just the URL if file sharing is not supported
          await navigator.share({
            url: videoUrl,
            title: translate('shareEntry.title'),
            text: translate('shareEntry.description'),
          });
        }
      } catch (error) {
        console.error('Share error:', error);
        // You might want to add some user feedback here
      }
    }
  };

  /**
   * Handles the download of the video blob
   */
  const handleDownload = () => {
    if (videoBlob) {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(videoBlob);
      downloadLink.download = 'video.mp4';
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  // Add useEffect to handle initial video play
  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;

      const handlePlay = () => {
        // Hide controls when video starts playing
        setShowControls(false);
      };

      const handlePause = () => {
        // Show controls when video is paused
        setShowControls(true);
      };

      const handleUserInteraction = () => {
        // Show controls on user interaction
        setShowControls(true);
        // Auto-hide controls after 3 seconds if video is playing
        if (!video.paused) {
          const timer = setTimeout(() => setShowControls(false), 3000);
          return () => clearTimeout(timer);
        }
      };

      video.addEventListener('play', handlePlay);
      video.addEventListener('pause', handlePause);
      video.addEventListener('click', handleUserInteraction);
      video.addEventListener('touchstart', handleUserInteraction);

      return () => {
        video.removeEventListener('play', handlePlay);
        video.removeEventListener('pause', handlePause);
        video.removeEventListener('click', handleUserInteraction);
        video.removeEventListener('touchstart', handleUserInteraction);
      };
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      aria-labelledby={`video-dialog-${jobId}`}
      PaperProps={{
        style: {
          borderRadius: theme.shape.borderRadius,
          overflow: 'hidden',
        },
      }}
    >
      <DialogContent
        sx={{ position: 'relative', padding: 0 }}
        {...swipeHandlers} // Apply swipe handlers to DialogContent
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[100],
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            padding: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1,
          }}
        >
          {/* Only show download button when not on native platform */}
          {!Capacitor.isNativePlatform() && (
            <Button
              variant="contained"
              startIcon={<FileDownloadIcon />}
              onClick={handleDownload}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              {translate('buttons.download')}
            </Button>
          )}

          {/* Only show share button on mobile/tablet */}
          {isMobileOrTablet && isShareSupported && (
            <Button
              variant="contained"
              startIcon={<ShareIcon />}
              onClick={handleShare}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              {translate('buttons.share')}
            </Button>
          )}
        </Box>

        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <video
            ref={videoRef}
            autoPlay
            controls={showControls}
            loop
            playsInline
            webkit-playsinline="true"
            src={videoUrl}
            poster={posterUrl}
            style={{
              width: isMobile ? '100%' : 'auto',
              height: isMobile ? 'auto' : '100%',
              maxHeight: isMobile ? '80vh' : 'none',
              objectFit: 'contain',
              cursor: 'pointer', // Add cursor pointer to indicate interactivity
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoModal;
