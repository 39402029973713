import { useState, useEffect, useRef } from 'react';
import { Box, Container, styled, Typography, useTheme, useMediaQuery } from '@mui/material';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from '@/components/animate';
import Iconify from '@/components/iconify';
import useLocales from '@/locales/useLocales';
import { PATH_AUTH } from '@/routes/paths'; // Ensure you have the correct path for authentication routes
import BigHomeButton from '@/components/buttons/homeMobile';

interface HeroImage {
  url: string;
}

const heroImages: HeroImage[] = [
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/93a24ccc-3bb6-4636-d951-b3c130efee00/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/339791e6-751f-4573-8b1d-f04e86f7b000/widejeedoo',
  },

  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/78218ff7-3162-426d-c776-acf85947af00/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/46ba048f-2d1f-4823-e092-215a10ef4d00/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/764afef0-4f15-4344-7b95-78ca05e49800/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/c0a51f8f-edd3-45de-82ec-2679794d0600/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/088164d0-803e-46bb-2fa8-d19f83489b00/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/3e3b99ff-5304-4599-bb78-d28a04261100/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/da3d2a7b-e6b0-4ebf-85bb-71b6e72bed00/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/823f13ba-d9cd-46b2-4529-8723f0565300/widejeedoo',
  },
  {
    url: 'https://imagedelivery.net/6WapkQGl0qFZS6Z7badEuQ/e611263e-aab2-4e4b-24dc-27586f912f00/widejeedoo',
  },
  // Add more images as needed
];

const StyledHeroSection = styled('div')<{ backgroundImage: string }>(({ theme, backgroundImage }) => ({
  position: 'relative',
  padding: theme.spacing(8, 0),
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '60vh',
  transition: 'background-image 1.5s ease-in-out',
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center', // Corrected alignment
  justifyContent: 'flex-end',
}));

const TextBox = styled(m(Box))(({ theme }) => ({
  position: 'absolute',
  zIndex: 2,
  left: theme.spacing(12),
  bottom: theme.spacing(2),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: theme.palette.common.white,
  textRendering: 'optimizeLegibility',
  textShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  transformOrigin: 'bottom left',
  transition: 'all 0.5s ease-in-out',
  maxWidth: '80%',
  scale: 1.0,
  [theme.breakpoints.up('sm')]: {
    maxWidth: '40%',
    scale: 1.0,
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '31%',
    scale: 1.2,
  },
}));

const HeroSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const { translate } = useLocales();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Determine if the device is mobile

  const preloadedIndices = useRef<Set<number>>(new Set());

  // Preload image function
  const preloadImage = (index: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (preloadedIndices.current.has(index)) {
        resolve();
        return;
      }
      const img = new Image();
      img.src = heroImages[index].url;
      img.onload = () => {
        preloadedIndices.current.add(index);
        resolve();
      };
      img.onerror = reject;
    });
  };

  // Add intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  // Modify the image rotation effect to only run when visible
  useEffect(() => {
    if (!isVisible) return; // Don't run if not visible

    // Preload the first image immediately
    preloadImage(0).catch((error) => console.error('Failed to preload image:', error));

    const handleChangeImage = async () => {
      const nextIndex = (currentIndex + 1) % heroImages.length;
      try {
        await preloadImage(nextIndex);
        setCurrentIndex(nextIndex);
      } catch (error) {
        console.error('Failed to preload image:', error);
        setCurrentIndex(nextIndex);
      }
    };

    const intervalId = setInterval(handleChangeImage, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex, isVisible]); // Add isVisible to dependencies

  // Modify the preload next image effect
  useEffect(() => {
    if (!isVisible) return; // Don't preload if not visible

    const nextIndex = (currentIndex + 1) % heroImages.length;
    preloadImage(nextIndex).catch((error) => console.error('Failed to preload image:', error));
  }, [currentIndex, isVisible]);

  const currentImage = heroImages[currentIndex];

  return (
    <StyledHeroSection ref={sectionRef} backgroundImage={isVisible ? currentImage.url : ''}>
      <Container component={MotionViewport}>
        <TextBox
          layout
          animate={{
            scale: isXs ? 0.8 : isSm ? 0.8 : isMdUp ? 1 : 0.8,
            maxWidth: isXs ? '80%' : isSm ? '80%' : isMdUp ? '80%' : '80%',
            width: isXs ? '80%' : isSm ? '80%' : isMdUp ? '80%' : '80%',
            left: isXs ? '10%' : isSm ? '10%' : '10%',
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <m.div variants={varFade().inDown}>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  fontSize: {
                    xs: '1.2rem',
                    sm: '1.5rem',
                    md: '2rem',
                  },
                }}
              >
                {translate('HeroSection.title')}
              </Typography>
            </m.div>
            <m.div variants={varFade().inUp}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'normal',
                  mt: 1,
                  fontSize: {
                    xs: '1rem',
                    sm: '1.2rem',
                    md: '1.5rem',
                  },
                }}
              >
                {translate('HeroSection.description')}
              </Typography>
            </m.div>
          </Box>
          {/* Sign In Button */}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <BigHomeButton
              text={translate('userLogin.signIn')}
              url={PATH_AUTH.login}
              fullWidth={isMobile}
              sx={{
                py: 2.5,
                px: 6,
                fontSize: isMobile ? '1rem' : '1.5rem',
                // float: 'right',
                backgroundColor: 'rgba(0, 0, 0, 0.3) !important',
                marginTop: theme.spacing(-3),
              }}
            />
          </Box>
        </TextBox>
      </Container>
    </StyledHeroSection>
  );
};

export default HeroSection;
