import { useEffect, useRef } from 'react';
import { forwardRef, ForwardedRef } from 'react';
import { m, useScroll, useSpring } from 'framer-motion';
import { Box, SxProps, Theme } from '@mui/material';
import { gsap } from 'gsap';

// ----------------------------------------------------------------------

interface ScrollProgressProps {
  color?: 'inherit' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
  size?: number;
  sx?: SxProps<Theme>;
  [key: string]: any; // For any other props
}

const ScrollProgress = forwardRef(function ScrollProgress(
  { color = 'primary', size = 3, sx, ...other }: ScrollProgressProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { scrollYProgress } = useScroll();
  const progressBarRef = useRef<HTMLDivElement>(null);
  const sparkRef = useRef<HTMLDivElement>(null);

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((value) => {
      if (progressBarRef.current && sparkRef.current) {
        const progress = value * 100; // Convert to percentage

        gsap.to(progressBarRef.current, {
          scaleX: value,
          ease: 'power3.out',
          duration: 0.5,
        });

        gsap.to(sparkRef.current, {
          x: `${progress}%`,
          ease: 'power3.out',
          duration: 0.5,
        });

        // Adding a glowing effect to the spark
        gsap.to(sparkRef.current, {
          boxShadow: '0 0 20px rgba(255, 255, 255, 0.8)',
          repeat: -1,
          yoyo: true,
          duration: 0.5,
        });
      }
    });

    return () => unsubscribe();
  }, [scrollYProgress]);

  return (
    <Box
      ref={ref}
      component={m.div}
      sx={{
        top: 0,
        left: 0,
        right: 0,
        height: size,
        zIndex: 1999,
        position: 'fixed',
        transformOrigin: '0%',
        bgcolor: `${color}.main`,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '0 4px 4px 0',
        ...(color === 'inherit' && {
          bgcolor: 'text.primary',
        }),
        ...sx,
      }}
      style={{ scaleX }}
      {...other}
    >
      <Box
        ref={progressBarRef}
        component={m.div}
        sx={{
          top: 0,
          left: 0,
          height: size,
          width: '100%',
          position: 'absolute',
          bgcolor: `${color}.main`,
          ...(color === 'inherit' && {
            bgcolor: 'text.primary',
          }),
        }}
      />
      <Box
        ref={sparkRef}
        component={m.div}
        sx={{
          top: 0,
          left: 0,
          height: size,
          width: size,
          position: 'absolute',
          bgcolor: `${color}.main`,
          borderRadius: '50%',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          ...(color === 'inherit' && {
            bgcolor: 'text.primary',
          }),
        }}
      />
    </Box>
  );
});

export default ScrollProgress;
