import Head from 'next/head';
import { styled, useTheme } from '@mui/material/styles';
import useLocales from '@/locales/useLocales';

import MainLayout from '@/layouts/main';

import HeroSection from './HeroSection';
import UseCaseSection from './UseCaseSection';

LandingPageNormal.getLayout = (page: React.ReactNode) => <MainLayout>{page}</MainLayout>;

const StyledRoot = styled('div')(({ theme }) => ({
  // paddingTop: theme.spacing(12),
  // paddingBottom: theme.spacing(10),
  backgroundColor: theme.palette.background.default,
}));

export default function LandingPageNormal() {
  const { translate } = useLocales();

  return (
    <>
      <Head>
        <title>{translate('AIPortraitShowcase.summaryTitle')} | MyPortrait Studio</title>
      </Head>
      <StyledRoot>
        {/* Hero Section */}
        <UseCaseSection />
      </StyledRoot>
    </>
  );
}
