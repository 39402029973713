import { useTheme } from '@mui/material/styles';
import { Box, Container, Typography, Grid } from '@mui/material';
import { m } from 'framer-motion';
import Head from 'next/head';
import useLocales from '@/locales/useLocales';
import { MotionViewport, varFade } from '@/components/animate';
import styles from './AIShortVideoShowcase.module.css';
import { useState, useEffect, useRef } from 'react';
import VideoModal from '@/sections/dashboard/videos/VideoModal';
import { PATH_AUTH } from '@/routes/paths';
import router from 'next/router';

function useElementOnScreen(options?: IntersectionObserverInit) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [options]);

  return { containerRef, isVisible };
}

export default function AIShortVideoShowcase() {
  const theme = useTheme();
  const { translate } = useLocales();

  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const videos = [
    'https://videos.myportrait.studio/5/43-5-197314.mp4',
    'https://videos.myportrait.studio/5/39-5-221858.mp4',
    'https://videos.myportrait.studio/5/44-5-202932.mp4',
    'https://videos.myportrait.studio/5/9-5-187473.mp4',
  ];

  const moreVideos = [
    'https://videos.myportrait.studio/1/85-1-222168.mp4',
    'https://videos.myportrait.studio/1/76-1-201091.mp4',
    'https://videos.myportrait.studio/1/79-1-200794.mp4',
    'https://videos.myportrait.studio/1/87-1-85040.mp4',
  ];

  const [loadedVideos, setLoadedVideos] = useState<{ [key: string]: boolean }>({});
  const [videoSources, setVideoSources] = useState<{ [key: string]: string }>({});

  const [playingVideos, setPlayingVideos] = useState<{ [key: string]: boolean }>({});

  const handleVideoClick = (videoSrc: string) => {
    setSelectedVideo(videoSrc);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  const { containerRef: firstGridRef, isVisible: isFirstGridVisible } = useElementOnScreen({
    threshold: 0.1,
    rootMargin: '50px',
  });

  const { containerRef: secondGridRef, isVisible: isSecondGridVisible } = useElementOnScreen({
    threshold: 0.1,
    rootMargin: '50px',
  });

  useEffect(() => {
    setPlayingVideos((prev) => {
      const newState = { ...prev };
      if (isFirstGridVisible) {
        videos.forEach((video) => {
          newState[video] = true;
        });
      }
      if (isSecondGridVisible) {
        moreVideos.forEach((video) => {
          newState[video] = true;
        });
      }
      if (!isFirstGridVisible && !isSecondGridVisible) {
        return {};
      }
      return newState;
    });
  }, [isFirstGridVisible, isSecondGridVisible]);

  useEffect(() => {
    setVideoSources((prev) => {
      const newState = { ...prev };
      if (isFirstGridVisible) {
        videos.forEach((video) => {
          newState[video] = video;
        });
      }
      if (isSecondGridVisible) {
        moreVideos.forEach((video) => {
          newState[video] = video;
        });
      }
      return newState;
    });
  }, [isFirstGridVisible, isSecondGridVisible]);

  const handleVideoLoad = (videoSrc: string) => {
    setLoadedVideos((prev) => ({
      ...prev,
      [videoSrc]: true,
    }));
  };

  return (
    <>
      <Head>
        <title>{translate('AIShortVideoShowcase.title')}</title>
      </Head>

      <div className={styles.neonBackground}>
        <div className={styles.lavaBackground}>
          <span></span>
        </div>
        <div className={styles.glowingOverlay}>
          <span></span>
        </div>
        <Container component={MotionViewport} sx={{ position: 'relative', zIndex: 2 }}>
          <Box sx={{ textAlign: 'center', mb: 8 }}>
            <m.div variants={varFade().inDown}>
              <Typography variant="h2" sx={{ fontWeight: 'bold', color: 'inherit' }}>
                {translate('AIShortVideoShowcase.experienceTheFuture')}
              </Typography>
            </m.div>
            <m.div variants={varFade().inDown}>
              <Typography variant="h5" sx={{ mt: 2, color: 'inherit' }}>
                {translate('AIShortVideoShowcase.transformYourPortraits')}
              </Typography>
            </m.div>
          </Box>

          <Grid container spacing={4} ref={firstGridRef}>
            {videos.map((videoSrc, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <m.div variants={varFade().inUp}>
                  <div
                    className={styles.videoBox}
                    style={{
                      borderRadius: theme.shape.borderRadius * 2,
                      boxShadow: `0 0 20px ${theme.palette.background.default}`,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleVideoClick(videoSrc)}
                  >
                    <video
                      src={videoSources[videoSrc]}
                      autoPlay={false}
                      loop
                      muted
                      playsInline
                      className={loadedVideos[videoSrc] ? styles.loaded : ''}
                      onLoadedData={(e) => {
                        handleVideoLoad(videoSrc);
                        if (playingVideos[videoSrc]) {
                          (e.target as HTMLVideoElement).play();
                        }
                      }}
                      ref={(el) => {
                        if (el) {
                          if (playingVideos[videoSrc]) {
                            el.play();
                          } else {
                            el.pause();
                          }
                        }
                      }}
                    ></video>
                  </div>
                </m.div>
              </Grid>
            ))}
          </Grid>

          <VideoModal open={Boolean(selectedVideo)} onClose={handleCloseModal} videoUrl={selectedVideo || ''} />

          <Box sx={{ textAlign: 'center', mt: 6 }}>
            <m.div variants={varFade().inUp}>
              <Typography
                variant="body1"
                sx={{
                  mt: 2,
                  color: 'inherit',
                  maxWidth: '800px',
                  margin: '0 auto',
                  fontSize: '1.1rem',
                  lineHeight: 1.6,
                }}
              >
                {translate('AIShortVideoShowcase.explanation')}
              </Typography>
            </m.div>
          </Box>

          <Grid container spacing={4} sx={{ mt: 8 }} ref={secondGridRef}>
            {moreVideos.map((videoSrc, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <m.div variants={varFade().inUp}>
                  <div
                    className={styles.videoBox}
                    style={{
                      borderRadius: theme.shape.borderRadius * 2,
                      boxShadow: `0 0 20px ${theme.palette.background.default}`,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleVideoClick(videoSrc)}
                  >
                    <video
                      src={videoSources[videoSrc]}
                      autoPlay={false}
                      loop
                      muted
                      playsInline
                      className={loadedVideos[videoSrc] ? styles.loaded : ''}
                      onLoadedData={(e) => {
                        handleVideoLoad(videoSrc);
                        if (playingVideos[videoSrc]) {
                          (e.target as HTMLVideoElement).play();
                        }
                      }}
                      ref={(el) => {
                        if (el) {
                          if (playingVideos[videoSrc]) {
                            el.play();
                          } else {
                            el.pause();
                          }
                        }
                      }}
                    ></video>
                  </div>
                </m.div>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: 'center', mt: 8, mb: 4 }}>
            <m.div variants={varFade().inUp}>
              <Typography variant="h3" sx={{ mb: 3, color: 'inherit' }}>
                {translate('AIShortVideoShowcase.readyToStart')}
              </Typography>
              <Typography variant="h6" sx={{ mb: 4, color: 'inherit', opacity: 0.87 }}>
                {translate('AIShortVideoShowcase.createYourOwn')}
              </Typography>
              <m.div variants={varFade().inUp}>
                <Box
                  component="button"
                  onClick={() => router.push(PATH_AUTH.register, undefined, { shallow: true })}
                  sx={{
                    background: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
                    border: 'none',
                    borderRadius: theme.spacing(1),
                    padding: '15px 40px',
                    fontSize: '1.2rem',
                    color: 'white',
                    cursor: 'pointer',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 6px 20px rgba(0,0,0,0.3)',
                    },
                  }}
                >
                  {translate('AIShortVideoShowcase.startCreating')}
                </Box>
              </m.div>
            </m.div>
          </Box>
        </Container>
      </div>
    </>
  );
}
