import React, { useState, useRef, useEffect } from 'react';
import { m } from 'framer-motion';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Container, Typography, Grid, IconButton, ToggleButtonGroup, ToggleButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MotionViewport, varFade } from '../../components/animate';
import useLocales from '@/locales/useLocales';
import BigHomeButton from '@/components/buttons/homeMobile';
import { PATH_AUTH } from '@/routes/paths';
import { atom, useAtom } from 'jotai';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { isMobile } from 'react-device-detect';
import { Gender } from '@api-types';
import router from 'next/router';

// State atom for gender selection
export const AIPortraitShowcaseGender = atom<Gender>(Gender.woman);

// Styled Components
const StyledRoot = styled('div')(({ theme }) => ({
  // marginTop: theme.spacing(9),
  padding: theme.spacing(5, 2),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 4),
  },
  background: `linear-gradient(135deg, ${theme.palette.background.paper} 25%, ${theme.palette.background.default} 75%)`,
  borderRadius: theme.shape.borderRadius * 2,
}));

const Header = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  // marginBottom: theme.spacing(6),
}));

const ImageBox = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  // paddingTop: '100%', // 1:1 Aspect Ratio
  overflow: 'hidden',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[8],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  aspectRatio: '3/4',
  cursor: 'pointer',
  '&:hover': {
    boxShadow: theme.shadows[16],
  },
  '& img': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transform: 'translate(-50%, -50%)',
  },
}));

const HighlightBox = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: theme.spacing(3, 5),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  transition: 'background 0.3s ease',
  '&:hover': {
    background: theme.palette.secondary.dark,
  },
}));

const ZoomOverlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300,
}));

// Custom hook for Intersection Observer
const useIntersectionObserver = (callback: () => void, options = { threshold: 0.1 }) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        callback();
        if (targetRef.current) {
          observer.unobserve(targetRef.current);
        }
      }
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [callback, options]);

  return targetRef;
};

// Main Component
export default function AIPortraitShowcase() {
  const { translate } = useLocales();
  const [gender, setGender] = useAtom(AIPortraitShowcaseGender);
  const theme = useTheme();

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [loadExamples, setLoadExamples] = useState(false);
  const [loadResults, setLoadResults] = useState(false);

  const examplesRef = useIntersectionObserver(() => setLoadExamples(true));
  const resultsRef = useIntersectionObserver(() => setLoadResults(true));

  const handleGenderChange = (event: React.MouseEvent<HTMLElement>, newGender: Gender | null) => {
    if (newGender) {
      setGender(newGender);
    }
  };

  const handleImageClick = (imageUrl: string) => {
    if (!isMobile) {
      setSelectedImage(imageUrl);
    }
  };

  const handleOverlayClose = () => {
    setSelectedImage(null);
  };

  const examples =
    gender === Gender.man
      ? [
          '/assets/images/uploadExampleMain/man/1.jpg',
          '/assets/images/uploadExampleMain/man/2.jpg',
          '/assets/images/uploadExampleMain/man/3.jpg',
          '/assets/images/uploadExampleMain/man/4.jpg',
        ]
      : [
          '/assets/images/uploadExampleMain/woman/7.jpg',
          '/assets/images/uploadExampleMain/woman/5.jpg',
          '/assets/images/uploadExampleMain/woman/6.jpg',
          '/assets/images/uploadExampleMain/woman/4.jpg',
        ];

  const results =
    gender === Gender.man
      ? [
          '/assets/images/uploadExampleMain/man/result1.jpg',
          '/assets/images/uploadExampleMain/man/result2.jpg',
          '/assets/images/uploadExampleMain/man/result3.jpg',
          '/assets/images/uploadExampleMain/man/result4.jpg',
        ]
      : [
          '/assets/images/uploadExampleMain/woman/result1.jpg',
          '/assets/images/uploadExampleMain/woman/result2.jpg',
          '/assets/images/uploadExampleMain/woman/result3.jpg',
          '/assets/images/uploadExampleMain/woman/result4.jpg',
        ];

  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        {/* Gender Switch */}
        <Box sx={{ textAlign: 'center', marginBottom: theme.spacing(6) }}>
          <ToggleButtonGroup
            value={gender}
            exclusive
            onChange={handleGenderChange}
            aria-label="gender selection"
            size={isMobile ? 'medium' : 'large'}
          >
            <ToggleButton value={Gender.man} aria-label="male" sx={{ px: 6 }}>
              {translate('AIPortraitShowcase.male')}
            </ToggleButton>
            <ToggleButton value={Gender.woman} aria-label="female" sx={{ px: 6 }}>
              {translate('AIPortraitShowcase.female')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {/* Upload Examples */}
        <Box
          ref={examplesRef}
          sx={{
            marginBottom: theme.spacing(6),
            padding: theme.spacing(2),
          }}
          component={m.div}
          variants={varFade().inUp}
          transition={{ duration: 0.3 }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            {translate('AIPortraitShowcase.uploadYourBestPhotos')}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 4 }}>
            {translate('AIPortraitShowcase.uploadBeautifulPicturesOfYourself')}
          </Typography>
          <Grid container spacing={3}>
            {examples.map((src, index) => (
              <Grid item xs={6} sm={3} key={index}>
                <m.div variants={varFade().inUp}>
                  <ImageBox onClick={() => handleImageClick(src)}>
                    {loadExamples ? (
                      <img src={src} alt={`Example ${index + 1}`} loading="lazy" />
                    ) : (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          bgcolor: 'background.paper',
                        }}
                      />
                    )}
                  </ImageBox>
                </m.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Result Showcase */}
        <Box
          ref={resultsRef}
          sx={{
            marginBottom: theme.spacing(12),
            padding: theme.spacing(2),
          }}
          component={m.div}
          variants={varFade().inUp}
          transition={{ duration: 0.3 }}
        >
          <Typography variant="h4" component="h2" gutterBottom>
            {translate('AIPortraitShowcase.resultTitle')}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" sx={{ mb: 4 }}>
            {translate('AIPortraitShowcase.resultDescription')}
          </Typography>
          <Grid container spacing={3}>
            {results.map((src, index) => (
              <Grid item xs={6} sm={3} key={index}>
                <m.div variants={varFade().inUp}>
                  <ImageBox onClick={() => handleImageClick(src)}>
                    {loadResults ? (
                      <img src={src} alt={`Result ${index + 1}`} loading="lazy" />
                    ) : (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          bgcolor: 'background.paper',
                        }}
                      />
                    )}
                  </ImageBox>
                </m.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Header Section */}
        {/* <Header>
          <Typography variant={isMobile ? 'h4' : 'h4'} gutterBottom>
            {translate('AIPortraitShowcase.title')}
          </Typography>
        </Header> */}

        {/* Call to Action */}
        <Box sx={{ textAlign: 'center', marginTop: theme.spacing(6) }}>
          <m.div variants={varFade().inUp}>
            <BigHomeButton
              text={translate('AIPortraitShowcase.getStartedNow')}
              url={PATH_AUTH.register}
              fullWidth={isMobile}
              onClick={() => router.push(PATH_AUTH.register, undefined, { shallow: true })}
              sx={{
                background: `linear-gradient(45deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
                border: 'none',
                borderRadius: theme.spacing(1),
                padding: '15px 40px',
                fontSize: '1.2rem',
                color: 'white',
                cursor: 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.3)',
                },
              }}
            />
          </m.div>
        </Box>

        {/* Zoomable Image Overlay */}
        {selectedImage && (
          <ZoomOverlay onClick={handleOverlayClose}>
            <IconButton
              onClick={handleOverlayClose}
              sx={{
                position: 'absolute',
                top: theme.spacing(2),
                right: theme.spacing(2),
                color: 'white',
                zIndex: 1400,
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={selectedImage}
                  alt="Zoomed"
                  style={{
                    maxWidth: '100vw',
                    maxHeight: '100vh',
                    width: 'auto',
                    height: 'auto',
                    borderRadius: '8px',
                  }}
                />
              </TransformComponent>
            </TransformWrapper>
          </ZoomOverlay>
        )}
      </Container>
    </StyledRoot>
  );
}
